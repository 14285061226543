// Generated by Framer (f7d95e4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import * as sharedStyle1 from "../css/fXkGZhD8V";
import * as sharedStyle from "../css/XdYKavQM_";

const enabledGestures = {PFcNtpif0: {hover: true}};

const serializationHash = "framer-mOtTd"

const variantClassNames = {PFcNtpif0: "framer-v-1amjxre"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, jNhKyC8oz: link ?? props.jNhKyC8oz} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, jNhKyC8oz, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "PFcNtpif0", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={jNhKyC8oz} motionChild nodeId={"PFcNtpif0"} openInNewTab scopeId={"ylFako6m4"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-1amjxre", className, classNames)} framer-q20h79`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PFcNtpif0"} ref={refBinding} style={{...style}} {...addPropertyOverrides({"PFcNtpif0-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-18zh1ui"} data-styles-preset={"XdYKavQM_"}>Read article</motion.p></React.Fragment>} className={"framer-1i3hug6"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"QOPsLmxL0"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"PFcNtpif0-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-1be8z8y"} data-styles-preset={"fXkGZhD8V"}>Read article</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/><SVG className={"framer-11ezigd"} data-framer-name={"Ic-link-arrow"} layout={"position"} layoutDependency={layoutDependency} layoutId={"NlLbZWocZ"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 0 0 L 24 0 L 24 24 L 0 24 Z\" fill=\"rgb(255,255,255)\"></path><path d=\"M 8 9 L 16 9 L 16 17 M 8 17 L 16 9\" fill=\"transparent\" stroke-width=\"1.2\" stroke=\"rgb(20,20,31)\" stroke-miterlimit=\"10\" stroke-dasharray=\"\"></path></svg>"} svgContentId={11964366304} withExternalLayout/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mOtTd.framer-q20h79, .framer-mOtTd .framer-q20h79 { display: block; }", ".framer-mOtTd.framer-1amjxre { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-mOtTd .framer-1i3hug6 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-mOtTd .framer-11ezigd { flex: none; height: 24px; position: relative; width: 24px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mOtTd.framer-1amjxre { gap: 0px; } .framer-mOtTd.framer-1amjxre > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-mOtTd.framer-1amjxre > :first-child { margin-left: 0px; } .framer-mOtTd.framer-1amjxre > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 110
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"c8Ci9QU0P":{"layout":["auto","auto"]}}}
 * @framerVariables {"jNhKyC8oz":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerylFako6m4: React.ComponentType<Props> = withCSS(Component, css, "framer-mOtTd") as typeof Component;
export default FramerylFako6m4;

FramerylFako6m4.displayName = "link-press";

FramerylFako6m4.defaultProps = {height: 24, width: 110};

addPropertyControls(FramerylFako6m4, {jNhKyC8oz: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerylFako6m4, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})